import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M1700 4178 c0 -19 69 -139 107 -185 62 -75 193 -158 293 -184 28 -7
196 -14 440 -19 419 -8 479 -14 665 -66 50 -13 141 -50 203 -80 92 -46 128
-70 197 -136 l84 -79 -19 68 c-32 113 -58 183 -96 252 -137 250 -378 401 -690
431 -149 14 -1184 13 -1184 -2z"/>
<path d="M3170 3646 c0 -2 16 -30 35 -62 88 -147 116 -322 79 -502 -40 -195
-158 -325 -345 -379 -37 -10 -117 -17 -256 -20 l-203 -5 0 104 c0 61 -6 128
-15 163 -40 152 -168 260 -322 273 l-63 5 0 -472 0 -472 433 3 c412 4 436 5
517 27 345 93 566 329 638 683 27 128 17 222 -33 323 -56 114 -129 186 -254
249 -53 27 -211 88 -211 82z"/>
<path d="M1540 1583 c-23 -9 -30 -24 -30 -67 0 -31 -3 -37 -12 -28 -33 33
-115 1 -142 -55 -20 -41 -20 -73 -2 -118 21 -50 83 -74 114 -43 10 10 15 10
22 -1 7 -12 14 -12 47 0 43 17 42 14 58 227 7 92 7 92 -17 91 -12 0 -30 -3
-38 -6z m-46 -148 c31 -32 -10 -134 -48 -119 -16 6 -28 57 -19 81 7 22 33 53
44 53 4 0 14 -7 23 -15z"/>
<path d="M3764 1580 c-18 -7 -22 -20 -28 -102 -3 -51 -9 -121 -13 -156 l-6
-63 39 7 c21 4 40 11 42 15 2 5 13 2 25 -6 32 -22 74 -19 102 9 49 49 57 154
14 197 -21 21 -69 25 -100 9 -18 -10 -19 -8 -13 45 7 53 6 55 -17 54 -13 0
-33 -4 -45 -9z m116 -145 c17 -20 6 -96 -16 -112 -47 -34 -78 63 -34 107 24
24 33 25 50 5z"/>
<path d="M1171 1567 c-17 -8 -33 -37 -56 -97 -25 -67 -37 -86 -54 -88 -22 -4
-26 -18 -12 -41 5 -8 6 -24 1 -36 -7 -18 -4 -25 16 -34 36 -16 41 -14 53 19 9
27 14 30 50 30 35 0 43 -4 56 -30 9 -16 19 -30 24 -30 16 0 71 22 71 28 0 4
-22 70 -48 147 -30 88 -53 141 -62 142 -8 2 -26 -3 -39 -10z m23 -149 c6 -36
5 -38 -18 -38 -16 0 -24 5 -21 13 28 80 29 81 39 25z"/>
<path d="M2775 1500 c-16 -4 -49 -5 -72 -2 -71 8 -123 -46 -123 -128 0 -77 75
-135 130 -100 9 5 19 5 26 -1 8 -6 20 -6 36 2 23 10 25 19 34 117 6 59 8 110
5 113 -3 3 -19 2 -36 -1z m-47 -58 c9 -6 10 -23 5 -63 -7 -45 -12 -55 -30 -57
-28 -4 -47 30 -39 71 10 50 33 68 64 49z"/>
<path d="M1678 1482 c-63 -38 -76 -144 -23 -189 34 -30 67 -37 118 -27 38 8
42 12 45 42 3 31 2 33 -17 23 -28 -16 -77 -13 -91 4 -11 13 -8 15 21 15 89 0
132 75 73 128 -30 27 -87 29 -126 4z m89 -49 c5 -14 -32 -36 -53 -31 -13 3
-12 6 2 26 16 24 44 26 51 5z"/>
<path d="M1885 1476 c-36 -36 -32 -71 10 -110 19 -18 35 -38 35 -44 0 -15 -25
-15 -51 -2 -17 9 -19 7 -19 -19 0 -43 53 -56 109 -26 54 28 55 72 1 118 -22
19 -40 39 -40 45 0 15 46 16 55 2 12 -19 23 0 16 28 -6 23 -13 27 -49 30 -34
3 -47 -2 -67 -22z"/>
<path d="M2091 1484 c-13 -9 -29 -32 -37 -50 -44 -104 25 -207 108 -163 19 10
20 8 16 -45 -3 -60 -1 -62 48 -44 l32 11 11 131 c7 72 14 141 17 154 5 25 -16
29 -62 12 -19 -8 -30 -8 -38 0 -15 15 -69 12 -95 -6z m87 -46 c17 -17 15 -93
-3 -108 -8 -6 -22 -10 -32 -8 -25 4 -30 71 -8 104 17 27 26 29 43 12z"/>
<path d="M2358 1493 c-30 -5 -36 -27 -41 -141 -2 -53 1 -66 17 -78 24 -17 60
-18 88 -3 15 8 22 8 27 0 5 -8 18 -7 47 2 l39 14 10 107 11 107 -36 -6 c-19
-4 -38 -9 -42 -11 -4 -3 -10 -36 -14 -75 -5 -55 -11 -73 -28 -85 -19 -13 -22
-13 -33 2 -6 9 -10 21 -8 27 2 7 6 42 9 80 5 51 4 67 -6 66 -7 -1 -25 -4 -40
-6z"/>
<path d="M2888 1493 c-23 -4 -27 -10 -32 -51 -15 -132 -17 -182 -7 -182 9 0
60 20 71 27 2 2 7 33 11 69 3 36 9 67 12 71 3 3 21 8 39 12 26 5 34 12 36 34
4 31 -9 34 -50 13 -24 -13 -28 -13 -28 0 0 8 -6 13 -12 13 -7 -1 -25 -4 -40
-6z"/>
<path d="M3088 1482 c-39 -23 -62 -76 -55 -124 10 -76 77 -113 158 -89 34 10
39 16 39 42 0 26 -2 29 -19 19 -29 -15 -77 -12 -91 5 -11 13 -8 15 21 15 19 0
49 9 67 20 26 16 32 26 32 54 0 66 -87 99 -152 58z m87 -52 c0 -16 -50 -38
-61 -27 -11 11 28 48 45 45 9 -2 16 -10 16 -18z"/>
<path d="M3313 1488 c-12 -6 -27 -23 -33 -38 -14 -34 -4 -58 43 -100 29 -26
30 -29 13 -36 -10 -4 -27 -2 -38 5 -26 16 -32 9 -21 -26 9 -25 14 -28 56 -27
53 0 87 24 87 60 0 36 -10 52 -46 75 -19 11 -34 27 -34 35 0 16 33 18 61 4 16
-9 19 -7 19 13 0 12 -5 28 -12 35 -15 15 -65 15 -95 0z"/>
<path d="M3495 1491 c-30 -6 -30 -7 -38 -91 -5 -53 -4 -93 2 -107 13 -28 56
-39 95 -25 19 7 32 8 36 2 4 -6 19 -5 42 2 42 13 43 16 54 146 l6 83 -38 -7
c-22 -4 -40 -8 -42 -9 -2 -2 -6 -35 -9 -74 -5 -56 -10 -75 -26 -86 -37 -26
-49 -9 -42 59 3 33 8 73 11 89 6 29 3 30 -51 18z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
